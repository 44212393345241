.Toastify__toast {
  border-radius: 8px;
  padding: 0;
  min-height: 52px;
}

.Toastify__toast--success {
  background-color: var(--color-G100);
}

.Toastify__toast--default {
  background: #3c3e44;
  color: var(--color-L100);
}

.Toastify__toast-body {
  margin: 0;
}

.Toastify__toast-container {
  min-width: 280px;
}

.Toastify__close-button {
  position: absolute;
  top: 17px;
  right: 15px;
}

.Toastify__close-button--default {
  color: var(--color-L100);
  opacity: 1;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    padding: 20px;
  }
}

.Toastify__toast-container--top-left {
  top: 2em;
  left: 2em;
}
.Toastify__toast-container--top-center {
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 2em;
  right: 2em;
}
.Toastify__toast-container--bottom-left {
  bottom: 2em;
  left: 2em;
}
.Toastify__toast-container--bottom-center {
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 2em;
  right: 2em;
}
